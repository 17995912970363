/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDrawerStore } from '~/stores/drawer'

export const useDrawer = () => {
  const drawerStore = useDrawerStore()
  const isDrawerShown = computed(() => drawerStore.isDrawerShown)
  const drawerOptions = computed(() => drawerStore.drawerOptions)

  function updateDrawerState(params: any) {
    drawerStore.updateDrawerState(params)
  }

  function closeDrawer() {
    if (isDrawerShown.value) {
      drawerStore.updateDrawerState({
        isDrawerShown: false,
        drawerOptions: undefined
      })
    }
  }

  return {
    isDrawerShown,
    drawerOptions,
    updateDrawerState,
    closeDrawer
  }
}
