import { useToastStore } from '~/stores/toast'

export default defineNuxtPlugin(() => {
  const toastStore = useToastStore()

  return {
    parallel: true,
    provide: {
      toast: {
        show(message: string, options: object) {
          toastStore.addToastMessage({
            duration: 4000,
            ...options,
            message,
            type: 'info'
          })
        },
        success(message: string, options: object) {
          toastStore.addToastMessage({
            duration: 4000,
            ...options,
            message,
            type: 'success'
          })
        },
        warning(message: string, options: object) {
          toastStore.addToastMessage({
            ...options,
            message,
            type: 'warning'
          })
        },
        error(message: string, options: object) {
          toastStore.addToastMessage({
            ...options,
            message,
            type: 'danger'
          })
        }
      }
    }
  }
})
