<template>
  <div>
    <VeeForm
      v-slot="{ meta: formMeta }"
      data-nw="profile-credentials-form"
      validate-on-mount
      @submit="submit"
    >
      <div class="mb-16">
        <label for="firstName" class="form-label">
          {{ $label('account.profile.firstName') }}
        </label>
        <VeeField
          v-slot="{ meta, field, errorMessage }"
          v-model="firstName"
          name="firstName"
          rules="required"
        >
          <input
            v-bind="field"
            id="firstName"
            type="text"
            :class="[
              'form-control',
              { 'is-valid': meta.valid },
              { 'is-invalid': !meta.valid && meta.touched }
            ]"
            aria-describedby="first-name"
          />
          <div v-show="!meta.valid && meta.touched" class="invalid-feedback">
            {{ errorMessage }}
          </div>
        </VeeField>
      </div>

      <div class="mb-16">
        <label for="lastName" class="form-label">
          {{ $label('account.profile.lastName') }}
        </label>
        <VeeField
          v-slot="{ meta, field, errorMessage }"
          v-model="lastName"
          name="lastName"
          rules="required"
        >
          <input
            v-bind="field"
            id="lastName"
            type="text"
            :class="[
              'form-control',
              { 'is-valid': meta.valid },
              { 'is-invalid': !meta.valid && meta.touched }
            ]"
            aria-describedby="last-name"
          />
          <div v-show="!meta.valid && meta.touched" class="invalid-feedback">
            {{ errorMessage }}
          </div>
        </VeeField>
      </div>

      <div class="mb-16">
        <label for="email" class="form-label">
          {{ $label('account.profile.email') }}
        </label>
        <VeeField
          v-slot="{ meta, field, errorMessage }"
          v-model="email"
          name="email"
          rules="required|email"
          @blur="checkEmail"
        >
          <input
            v-bind="field"
            id="email"
            type="email"
            :class="[
              'form-control',
              { 'is-valid': meta.valid },
              { 'is-invalid': !meta.valid && meta.touched }
            ]"
            autocomplete="email"
            aria-describedby="email"
          />
          <div v-show="!meta.valid && meta.touched" class="invalid-feedback">
            {{ errorMessage }}
          </div>
          <SuggestedEmail
            :suggested-email="suggestedEmail"
            :update-email="updateEmail"
          />
        </VeeField>
      </div>

      <BaseButton
        :disabled="!formMeta.valid"
        :class="{ loading: isLoading }"
        class="mt-16 w-100"
        variant="primary"
        type="submit"
        data-nw="profile-credentials-submit"
      >
        {{ $label('common.save') }}
      </BaseButton>
    </VeeForm>
  </div>
</template>

<script>
import { useUserStore } from '~/stores/user'
import { useAccountStore } from '~/stores/account'
import ValidationMixin from '~/mixins/validation'
import SuggestedEmail from '~/components/forms/SuggestedEmail'
import { BACKEND_ERRORS } from '~/utils/constants'
import { useDrawer } from '~/composables/useDrawer'

export default {
  components: {
    SuggestedEmail
  },
  mixins: [ValidationMixin],
  setup() {
    const { closeDrawer } = useDrawer()
    const userStore = useUserStore()
    const accountStore = useAccountStore()

    return {
      closeDrawer,
      userStore,
      accountStore
    }
  },
  data() {
    return {
      isLoading: false,
      firstName: '',
      email: '',
      lastName: '',
      suggestedEmail: ''
    }
  },
  computed: {
    loggedInUser() {
      return this.userStore.loggedInUser
    }
  },
  created() {
    this.firstName = this.loggedInUser.firstName
    this.lastName = this.loggedInUser.lastName
    this.email = this.loggedInUser.email
  },
  methods: {
    async submit() {
      try {
        this.isLoading = true

        await this.accountStore.updateProfile({
          firstName: this.firstName.trim(),
          lastName: this.lastName.trim(),
          email: this.email.trim()
        })

        this.$toast.success(
          this.$label('messages.updateProfile.successMessage')
        )

        this.closeDrawer()
      } catch (error) {
        const errorLabel = this.$error.getLabel(error)
        const isValidationError = BACKEND_ERRORS.includes(errorLabel)
        const label = isValidationError
          ? `errors.account.${errorLabel}`
          : 'messages.errorOccuredMessage'

        this.$toast.error(this.$label(label))
        this.$sentryCaptureException(error)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
