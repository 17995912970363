import { defineStore } from 'pinia'
import { $fetchApi } from '~/composables/useFetchApi'

export const useLabelsStore = defineStore('labels', {
  state: () => ({
    labels: {}
  }),
  actions: {
    async getLabels() {
      const response = await $fetchApi('/labels')

      if (response) {
        const labels = JSON.parse(response)
        this.labels = labels
      }

      return response
    }
  }
})
