import { defineStore } from 'pinia'
import { useRuntimeConfig } from '#imports'
import { $fetchApi } from '~/composables/useFetchApi'

export const getDefaultPaymentState = () => ({
  isFetchingPaymentSession: false,
  paymentSessionData: {},
  paymentMethod: null,
  purchasedItems: {},
  refund: {}
})

export const usePaymentStore = defineStore('payment', {
  state: () => getDefaultPaymentState(),
  getters: {
    paymentSession: (state) => state.paymentSessionData.paymentSession,
    paymentSessionId: (state) => state.paymentSessionData.paymentSessionId,
    orderNumber: (state) => state.paymentSessionData.orderNumber,
    paymentSessionAmount: (state) => state.paymentSessionData.amount || 0,
    purchasedSubscription: (state) =>
      state.purchasedItems.purchasedSubscriptionProduct,
    purchasedItemsAmount: (state) => state.purchasedItems.amount,
    shopPurchase: (state) => state.purchasedItems.books,
    amountToBeRefunded: (state) => state.refund.amountToBePaid,
    monthsToBeRefunded: (state) => state.refund.monthsToBeRefunded
  },
  actions: {
    async getPaymentSession(params) {
      const config = useRuntimeConfig()
      this.isFetchingPaymentSession = true
      this.purchasedItems = {}

      const response = await $fetchApi('/v4/payments/session', {
        method: 'POST',
        body: {
          ...params,
          isShop: false,
          enableBasicAndPremium: config.public.BASIC_AND_PREMIUM === '1'
        }
      })

      this.paymentSessionData = response
      this.isFetchingPaymentSession = false
    },

    async getSessionData(_, sessionId) {
      const response = await $fetchApi(
        `/v3/payments/session?sessionId=${sessionId}`
      )

      if (response) {
        return response.paymentSession
      }
    },

    async completePayment({ orderNumber }) {
      this.purchasedItems = {}

      const response = await $fetchApi('/v4/payments/success', {
        method: 'POST',
        body: { orderNumber }
      })

      if (response) {
        this.purchasedItems = response
      }
    },

    async getRefundInfo() {
      const response = await $fetchApi(`payments/v1/refund/prediction`)

      if (response) {
        this.refund = response
      }
    },

    setPaymentMethod(paymentMethod) {
      this.paymentMethod = paymentMethod
    },

    setPurchasedItems(purchasedItems) {
      this.purchasedItems = purchasedItems
    },

    clearPaymentSession() {
      this.$state = getDefaultPaymentState()
    }
  },
  share: {
    omit: [
      'isFetchingPaymentSession',
      'paymentSessionData',
      'paymentMethod',
      'refund'
    ],
    enable: true,
    initialize: true
  }
})
