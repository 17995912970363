import { defineStore } from 'pinia'

export const useRouteStore = defineStore('route', {
  state: () => ({
    from: undefined
  }),
  actions: {
    setFrom(data) {
      this.from = data
    }
  }
})
