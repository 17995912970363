import { defineStore } from 'pinia'
import { useUserStore } from './user'
import { $fetchApi } from '~/composables/useFetchApi'

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

function getMonthLabel(month) {
  return `common.${months[month]}`
}

export const useBooksStore = defineStore('books', {
  state: () => ({
    monthlyBooksData: {},
    bookDetailsData: {},
    extraCategoriesData: null,
    chosenBooksData: {}
  }),
  getters: {
    categories: () => {
      return (variant = [0]) => {
        const now = new Date()
        const categories = variant.map((monthModifier) => {
          const newDate = new Date(now.getTime())
          newDate.setDate(1)
          newDate.setMonth(now.getMonth() + monthModifier)

          return {
            date: newDate,
            name: getMonthLabel(newDate.getMonth()),
            value: `${newDate.getFullYear()}-${(newDate.getMonth() + 1)
              .toString()
              .padStart(2, '0')}`
          }
        })

        return categories
      }
    },
    monthlyBooks: (state) => {
      return (month) => state.monthlyBooksData[month] || []
    },
    bookDetails: (state) => {
      return (bookSlug = '') => {
        const slug = bookSlug.toLowerCase()

        return state.bookDetailsData[slug] || {}
      }
    },
    getBooksForMonth() {
      return (month) => {
        return this.monthlyBooks(month).map((book) => Object.assign({}, book))
      }
    },
    extraCategories: (state) => state.extraCategoriesData || [],
    chosenBooks: (state) => {
      return (month) => state.chosenBooksData[month]?.chosenBooks
    },
    allChosenBooks: (state) => state.chosenBooksData,
    choicesLeft: (state) => {
      return (month) => state.chosenBooksData[month]?.choicesLeft
    }
  },
  actions: {
    async getMonthlyBooks(category) {
      if (!category) {
        const categories = this.categories()
        const { value } = categories.pop()
        category = value
      }

      const { monthlyBooksData } = this

      if (monthlyBooksData && monthlyBooksData[category]) {
        return monthlyBooksData[category]
      }

      let params = {}

      if (category) {
        params = {
          category
        }
      }

      const data = await $fetchApi('v2/proxy/books/monthly-books', { params })

      if (category && data) {
        this.monthlyBooksData = {
          ...this.monthlyBooksData,
          [category]: data.map((book) => ({
            ...book,
            categoryCode: category
          }))
        }
      }

      return data
    },
    async getExtraCategories() {
      const userStore = useUserStore()

      if (!userStore.data) {
        return []
      }

      if (this.extraCategoriesData) {
        return this.extraCategoriesData
      }

      const data = await $fetchApi('v3/books/extraCategory/subscription')

      this.extraCategoriesData = data

      return data
    },
    clearBooksData() {
      this.extraCategoriesData = null
      this.chosenBooksData = {}
    },
    async getBookDetails(slug) {
      const response = await $fetchApi('proxy/books/monthly-books/details', {
        params: { slug }
      })

      this.bookDetailsData = {
        ...this.bookDetailsData,
        [slug]: {
          ...response,
          slug
        }
      }

      return response
    },
    async getEbookDownloadUrl({
      id,
      format,
      language,
      categoryCode,
      isReader
    }) {
      const deviceType = isReader ? 'Reader' : 'Website'
      const response = await $fetchApi(`/v3/books/my-ebooks/${id}/download`, {
        method: 'POST',
        body: {
          format,
          language,
          categoryCode
        },
        headers: { 'X-Device-Type': deviceType }
      })

      return response
    },
    async getAudioBookDownloadUrl({
      id,
      format,
      language,
      categoryCode,
      isPlayer
    }) {
      const deviceType = isPlayer ? 'Reader' : 'Website'

      const response = await $fetchApi(
        `/v3/books/my-audiobooks/${id}/download`,
        {
          method: 'POST',
          body: {
            format,
            language,
            categoryCode
          },
          headers: { 'X-Device-Type': deviceType }
        }
      )

      return response
    },
    async getChosenBooks(category) {
      const response = await $fetchApi(
        `books/v1/userBookChosen/basic?category=${category}`
      )

      const { choicesLeft, chosenBooks } = response

      const data = {
        [category]: {
          choicesLeft,
          chosenBooks
        }
      }

      this.chosenBooksData = {
        ...this.chosenBooksData,
        ...data
      }

      return chosenBooks
    },
    async confirmChosenBook(bookSlug) {
      await $fetchApi('/books/v1/userBookChosen', {
        method: 'POST',
        body: JSON.stringify(bookSlug)
      })
    }
  }
})
