<template>
  <div class="books-background">
    <BCIcon class="bc-icon" :style="{ fill: iconColor }" />
    <div class="books-background__books d-flex flex-wrap">
      <div
        v-for="book in books"
        :key="book.title"
        class="books-background__book slide-in-up"
      >
        <img
          :src="`${book.cover}&width=336&height=516&fit=cover`"
          class="books-background__book__cover"
          :alt="book.title"
          :width="168"
          :height="258"
          fetchpriority="high"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'
import { useBooksStore } from '~/stores/books'
import BCIcon from '~/assets/icons/bc-icon.svg'
import { transformMonthFromReadable } from '~/utils/monthFormatter'

export default {
  components: {
    BCIcon
  },
  props: {
    iconColor: {
      type: String,
      default: '#5BC4A6'
    }
  },
  setup() {
    const booksStore = useBooksStore()
    const { categories, getBooksForMonth } = storeToRefs(booksStore)

    return {
      categories,
      getBooksForMonth
    }
  },
  computed: {
    books() {
      return this.getBooksForMonth(this.currentMonth)
    },
    currentMonth() {
      const categories = this.categories([0])
      const { value } = categories.pop()
      const month = value
      return transformMonthFromReadable(month)
    }
  }
}
</script>

<style lang="scss" scoped>
.books-background {
  position: relative;
}

.bc-icon {
  z-index: 0;
  position: absolute;
  width: 210vw;
  min-width: 700px;
  top: -22vw;
  transform: rotate(149.63deg);
}

.books-background__books {
  z-index: 1;
  position: relative;
  width: 112vw;
  height: 85.68vw;
  min-width: 400px;
  min-height: 330px;
  left: -27px;
  margin-top: max(55px, 14vw);
  margin-bottom: 38px;
  justify-content: space-evenly;
  gap: 8px;
  transform: rotate(12.2deg);
}

.books-background__book {
  flex-basis: 22.8%;

  &:nth-child(2),
  &:nth-child(4) {
    margin-top: 16px;
  }
  &:nth-child(5),
  &:nth-child(7) {
    margin-top: -16px;
  }
}
@for $i from 1 through 8 {
  .books-background__book:nth-child(#{$i}n) {
    transform: translateY(110vh);
    animation: slideInFromBottom 0.5s forwards ease-out;
    animation-delay: #{$i * 0.2}s;
  }
}
.books-background__book__cover {
  width: 100%;
  height: auto;
  border-radius: 5.96px;
  box-shadow: 0px 0.6px 2.38px 0px rgba(0, 0, 0, 0.25);
}

@include media-breakpoint-up(sm) {
  .books-background__books {
    width: 614px;
    height: 470px;
    gap: 16px;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
  }
}

@include media-breakpoint-up(md) {
  .bc-icon {
    width: 104vw;
    right: -26vw;
    top: -6vw;
  }

  .books-background__books {
    transform: rotate(-9.66deg);
    margin: -50px 0 0 auto;
    width: 63vw;
    height: 48.195vw;
    gap: 6px;
  }

  .books-background__book__cover {
    border-radius: 10.07px;
    box-shadow: 0px 1.01px 4.03px 0px rgba(0, 0, 0, 0.25);
  }
}

@include media-breakpoint-up(lg) {
  .books-background {
    position: absolute;
    top: 50px;
    left: 50vw;
  }

  .bc-icon {
    left: -6vw;
    top: -368px;
    width: 1808px;
  }

  .books-background__books {
    width: 530px;
    height: 405px;
    gap: 8px;
    margin: 0;
    top: unset;
    left: unset;
    bottom: unset;
  }

  .books-background__book__cover {
    width: 120px;
  }

  .books-background__book {
    &:nth-child(2),
    &:nth-child(4) {
      margin-top: 24px;
    }
    &:nth-child(5),
    &:nth-child(7) {
      margin-top: -24px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .books-background {
    top: 80px;
    left: 45vw;
  }

  .bc-icon {
    left: -6vw;
    top: -368px;
    width: 1808px;
  }

  .books-background__books {
    width: 740px;
    height: 565.5px;
    gap: 16px;
    margin: 0;
  }

  .books-background__book__cover {
    width: 168px;
  }
}

@include media-breakpoint-up(xxl) {
  .books-background {
    left: 52vw;
  }
}
</style>
