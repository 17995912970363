<!-- eslint-disable vue/no-v-html -->
<template>
  <BasePopover
    :position="position"
    :mobile-position="mobilePosition"
    :is-visible="isVisible"
    :mobile-position-breakpoint="mobilePositionBreakpoint"
    :custom-class="`onboarding-${onboardingVariant}-${onboardingStep}`"
  >
    <slot></slot>

    <template v-if="isVisible" #body="{ close: closePopover }">
      <div class="d-flex justify-content-between align-items-center mb-24">
        <p class="h4 mb-0 text-start">
          {{ title }}
        </p>
        <BaseButton
          aria-label="Close"
          class="reset-btn-styling d-flex align-items-center"
          variant="naked"
          @click="() => onCloseClicked(closePopover)"
        >
          <CloseIcon width="14" height="14" />
        </BaseButton>
      </div>

      <div class="text-start">
        <p class="onboarding__text mb-0" v-html="text" />

        <template v-if="extraItems">
          <OnboardingExtraInfo
            v-for="item in extraItems"
            :key="item.text"
            :text="item.text"
            :href="item.href"
          />
        </template>

        <OnboardingPopoverFooter :on-next="onNext" :on-prev="onPrev" />
      </div>
    </template>
  </BasePopover>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useOnboardingStore } from '~/stores/onboarding'
import BasePopover from '~/components/popover/BasePopover'
import OnboardingExtraInfo from '~/components/onboarding/OnboardingExtraInfo'
import OnboardingPopoverFooter from '~/components/onboarding/OnboardingPopoverFooter'
import CloseIcon from '~/assets/icons/close.svg'

export default {
  components: {
    BasePopover,
    OnboardingExtraInfo,
    OnboardingPopoverFooter,
    CloseIcon
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: 'bottom'
    },
    mobilePosition: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      required: true
    },
    onNext: {
      type: Function,
      default: () => {}
    },
    onPrev: {
      type: Function,
      default: () => {}
    },
    extraItems: {
      type: Array,
      default: undefined
    },
    isVisible: {
      type: Boolean,
      default: false
    },
    mobilePositionBreakpoint: {
      type: Number,
      default: undefined
    }
  },
  computed: {
    ...mapState(useOnboardingStore, {
      onboardingStep: 'step',
      onboardingVariant: 'variant'
    })
  },
  methods: {
    ...mapActions(useOnboardingStore, ['nextStep']),
    onCloseClicked(closePopover) {
      closePopover()
      this.nextStep(0)
    }
  }
}
</script>
