/* eslint-disable @typescript-eslint/no-explicit-any */
// ADJUSTED REFRESH HANDLER BASED ON @sidebase/nuxt-auth IMPLEMENTATION
import type { RefreshHandler } from '@sidebase/nuxt-auth'
import { useAuth, useRuntimeConfig, useNuxtApp } from '#imports'

class CustomRefreshHandler implements RefreshHandler {
  auth?: ReturnType<typeof useAuth>
  runtimeConfig?: any
  private boundVisibilityHandler: typeof this.visibilityHandler

  constructor() {
    this.boundVisibilityHandler = this.visibilityHandler.bind(this)
  }

  async refreshToken() {
    try {
      await this.auth?.refresh()
      const { $localStorage } = useNuxtApp()
      $localStorage.addItem('sessionStatus', 'authenticated', 1)
    } catch {
      const { rawRefreshToken } = useAuthState()
      rawRefreshToken.value = null
    }
  }

  init(): void {
    this.runtimeConfig = useRuntimeConfig().public.auth
    this.auth = useAuth()

    document.addEventListener(
      'visibilitychange',
      this.boundVisibilityHandler,
      false
    )
  }

  destroy(): void {
    document.removeEventListener(
      'visibilitychange',
      this.boundVisibilityHandler,
      false
    )

    this.auth = undefined
    this.runtimeConfig = undefined
  }

  shouldRefresh(): boolean {
    const isDocumentVisible = document.visibilityState === 'visible'
    const refreshToken = this.auth?.refreshToken.value
    const lastRefreshAt = this.auth?.lastRefreshedAt.value || new Date()
    const now = new Date().getTime()
    const difference = now - lastRefreshAt.getTime()

    if (isDocumentVisible && refreshToken && difference > 60 * 60 * 1000) {
      return true
    }

    return false
  }

  async visibilityHandler(): Promise<void> {
    if (this.shouldRefresh()) {
      await this.refreshToken()
    }
  }
}

export default new CustomRefreshHandler()
