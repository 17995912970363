<script setup>
import { useUserStore } from '~/stores/user'
import { useRouteStore } from '~/stores/route'
import { useLabelsStore } from '~/stores/labels'
import { useToastStore } from '~/stores/toast'
import { useGtm } from '@gtm-support/vue-gtm'
import { useDrawer } from '~/composables/useDrawer'

const userStore = useUserStore()
const routeStore = useRouteStore()
const labelsStore = useLabelsStore()
const toastStore = useToastStore()
const { $sentryCaptureException, $gtmTracking } = useNuxtApp()
const { sentrySetUserId } = useSentry()
const router = useRouter()
const gtm = useGtm()
const { data } = useAuth()
const { closeDrawer } = useDrawer()

const { error } = await useAsyncData(() => {
  userStore.setUser(data.value)
  return labelsStore.getLabels()
})

if (error.value) {
  $sentryCaptureException(error.value)
}

watch(data, (newData) => {
  if (!newData && userStore.loggedInUser) {
    userStore.setUser(false)
  } else if (newData) {
    userStore.setUser(newData)
  }
})

const updateTabs = (event) => {
  if (event.key === 'sessionStatus' || event.key === 'chosenBook') {
    window.location.reload()
  }
}

onMounted(() => {
  window.addEventListener('storage', updateTabs)
})

onBeforeUnmount(() => {
  window.removeEventListener('storage', updateTabs)
})

router.afterEach((_, from) => {
  routeStore.setFrom(from)

  // remove ocp scroll lock class on routing
  document.body.classList.remove('scroll-lock')
  document.body.removeAttribute('style')

  toastStore.dismissToastMessage()
  closeDrawer()

  if (gtm.enabled) {
    $gtmTracking.pushNuxtRouteEvent()
  }
})

if (import.meta.client) {
  sentrySetUserId()
}
</script>

<template>
  <NuxtLayout>
    <NuxtPage :page-key="(route) => route.path" />
  </NuxtLayout>
</template>

<style>
.page--start {
  transition: all 0.2s ease-out;
  opacity: 0.2;
}
.page--finish {
  opacity: 1 !important;
}
</style>
