<script setup>
import { useUserStore } from '~/stores/user'
import { useLabelsStore } from '~/stores/labels'

const props = defineProps({
  error: {
    type: Object,
    required: true
  }
})

const userStore = useUserStore()
const labelsStore = useLabelsStore()
const { $sentryCaptureException, $label } = useNuxtApp()
const { getSession, data } = useAuth()

const { error: asyncDataError } = await useAsyncData(async () => {
  await labelsStore.getLabels()
  const userData = await getSession()
  userStore.setUser(userData)

  return true
})

if (asyncDataError.value) {
  $sentryCaptureException(asyncDataError.value)
}

watch(data, (newData) => {
  if (!newData && userStore.loggedInUser) {
    userStore.setUser(false)
  } else if (newData) {
    userStore.setUser(newData)
  }
})

const title = computed(() => {
  return props.error.statusCode === 404
    ? $label('common.404error.title')
    : $label('common.error.generalTitle')
})
const text = computed(() => {
  return props.error.statusCode === 404
    ? $label('common.404error.subTitle')
    : ''
})

useHead({
  title: `Bookchoice • ${title.value}`
})
</script>

<template>
  <NuxtLayout name="default">
    <ErrorPageContent :title="title" :text="text" />
  </NuxtLayout>
</template>
