import { defineStore } from 'pinia'

export const useDrawerStore = defineStore('drawer', {
  state: () => ({
    drawerOptions: {},
    isDrawerShown: false
  }),
  actions: {
    updateDrawerState({ isDrawerShown, drawerOptions }) {
      this.isDrawerShown = isDrawerShown

      if (drawerOptions) {
        this.drawerOptions = drawerOptions
      }
    }
  }
})
