<template>
  <div
    v-if="cartData.items && cartData.items.length"
    class="d-flex flex-column"
  >
    <div class="w-100 d-flex mt-8 justify-content-between mb-24">
      <span class="total-price">{{ $label('cart.totalPrice') }}</span>
      <span class="price">{{ totalPrice }}</span>
    </div>
    <BaseButton
      variant="call-to-action"
      class="w-100"
      :disabled="removingItem"
      @click="onClick"
    >
      {{ $label('cart.pay') }}
    </BaseButton>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useShopCartStore } from '~/stores/shopCart'
import { useDrawer } from '~/composables/useDrawer'
import currencyFormatter from '~/utils/currencyFormatter'

export default {
  setup() {
    const { closeDrawer } = useDrawer()

    return {
      closeDrawer
    }
  },
  computed: {
    ...mapState(useShopCartStore, ['cartData', 'removingItem']),
    totalPrice() {
      return currencyFormatter(this.cartData.totalPrice)
    }
  },
  methods: {
    onClick() {
      this.closeDrawer()
      this.$router.push('/shop/betalen')
    }
  }
}
</script>

<style lang="scss" scoped>
.total-price {
  font-size: 16px;
  font-family: $font-family-title;
}
</style>
