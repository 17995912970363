interface RedirectResponse {
  createDate: string
  destinationUrl: string
  name: string
  sourceUrl: string
  uid: string
  updateDate: string
}

export default defineNuxtRouteMiddleware(async (to) => {
  const {
    query,
    params: { slug }
  } = to
  // Nuxt 3 seems to serve all non found assets as pages (images, js), do not make request for those
  if (/\.(png|js|txt)$/gi.test(slug as string)) {
    throw createError({ statusCode: 404, statusMessage: 'Assets not found' })
  }

  if (slug) {
    const { data, error } = await useAsyncData(
      () =>
        $fetchApi(
          `/nbi/promotion/customizedredirects/sourceurl/${slug}`
        ) as Promise<RedirectResponse>
    )

    if (data.value) {
      return navigateTo({ path: data.value.destinationUrl, query })
    }

    if (error.value) {
      throw createError({ statusCode: 404, statusMessage: 'Page Not Found' })
    }
  }
})
