import { defineStore } from 'pinia'
import { useAuth, useRuntimeConfig } from '#imports'

export const useUserStore = defineStore('user', {
  state: () => ({
    data: false
  }),
  getters: {
    loggedInUser: (state) => state.data,
    isAuthenticated: (state) => !!state.data,
    canBuySubscription: (state) => {
      if (!state.data) return true

      const { scheduledAutoRenewal, hasFutureSubscriptions } = state.data

      return !scheduledAutoRenewal && !hasFutureSubscriptions
    },
    canUpgradeToPremium: (state) => {
      const config = useRuntimeConfig()

      return (
        config.public.BASIC_AND_PREMIUM === '1' && !!state.data?.isAbleToUpgrade
      )
    },
    hasValidSubscription: (state) => !!state.data?.currentMonthSubscription,
    hasAutoRenewalSubscription: (state) => {
      const user = state.data

      return (
        user &&
        user.currentMonthSubscription &&
        user.currentMonthSubscription.isAutoRenewal
      )
    },
    hasBasicSubscription: (state) => {
      const config = useRuntimeConfig()
      const featureLive = config.public.BASIC_AND_PREMIUM === '1'
      const user = state.data

      return !!(
        featureLive &&
        user &&
        user.currentMonthSubscription &&
        !user.currentMonthSubscription.isPremium
      )
    },
    hasBasicSubForPrevMonth: (state) => {
      const config = useRuntimeConfig()
      const featureLive = config.public.BASIC_AND_PREMIUM === '1'
      const user = state.data

      return !!(
        featureLive &&
        user &&
        user.previousMonthSubscription &&
        !user.previousMonthSubscription.isPremium
      )
    },
    hasPausedSubscription: (state) => !!state.data?.pauseSubscription
  },
  actions: {
    async updateUserData() {
      const { data, getSession } = useAuth()
      await getSession()

      this.data = data.value
    },
    setUser(newData) {
      this.data = newData
    },
    setUserPhoto(photo) {
      this.data = {
        ...this.data,
        photo
      }
    }
  }
})
