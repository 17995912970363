<template>
  <div data-testid="cart-drawer">
    <div v-if="isLoading" class="d-flex justify-content-center mt-32">
      <BaseSpinner />
    </div>

    <div v-else-if="cartNotEmpty">
      <div
        v-for="item in cartData.items"
        :key="item.itemUid"
        class="cart-item position-relative"
      >
        <CartItem
          :item="item.bookDetails"
          :cart-uid="cartData.uid"
          :product-uid="item.itemUid"
          clickable
        />
        <hr class="divider" />
      </div>
    </div>

    <div
      v-else
      class="d-flex flex-column align-items-center mt-24"
      data-testid="empty-cart"
    >
      <p class="">{{ $label('cart.emptyCart') }}</p>

      <EmptyCartIcon />
      <BaseButton
        class="mt-24"
        data-testid="back-to-shop-btn"
        @click="onGoToShopClick"
      >
        {{ $label('cart.goToShop') }}
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useShopCartStore } from '~/stores/shopCart'
import { useDrawer } from '~/composables/useDrawer'
import CartItem from '~/components/shopCart/CartItem'
import EmptyCartIcon from '~/assets/icons/empty_cart.svg'

export default {
  components: {
    CartItem,
    EmptyCartIcon
  },
  setup() {
    const { closeDrawer } = useDrawer()

    return {
      closeDrawer
    }
  },
  computed: {
    ...mapState(useShopCartStore, ['cartData', 'isLoading']),
    cartNotEmpty() {
      return !!(this.cartData.items && this.cartData.items.length)
    }
  },
  methods: {
    onGoToShopClick() {
      this.closeDrawer()

      if (this.$route.fullPath !== '/shop') {
        this.$router.push('/shop')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cart-item {
  opacity: 0;
}

@for $i from 1 through 8 {
  .cart-item:nth-child(#{$i}n) {
    animation: fadeInUp 0.3s forwards ease-out;
    animation-delay: #{$i * 0.2}s;
  }
}
</style>
