<script setup>
import { useOnboardingStore } from '~/stores/onboarding'
import OnboardingPopover from '~/components/onboarding/OnboardingPopover'
import GooglePlayBadge from '~/assets/icons/google-play-badge.svg'
import AppStoreBadge from '~/assets/icons/app-store-badge.svg'
import BCIcon from '~/assets/icons/bc-icon.svg'
import BaseLazyImage from '~/components/BaseLazyImage'
import { ONBOARDING_VARIANTS } from '~/utils/constants'

const config = useRuntimeConfig()
const imageUrl = config.public.IMAGE_RESIZE_URL

const props = defineProps({
  showOnboarding: {
    type: Boolean,
    default: false
  }
})

const onboardingStore = useOnboardingStore()
const onboardingStep = computed(() => onboardingStore.step)
const onboardingVariant = computed(() => onboardingStore.variant)
const onboardingLabel = computed(() => onboardingStore.label)
const onboardingVisible = computed(() => {
  return (
    props.showOnboarding &&
    onboardingStep.value === 2 &&
    onboardingVariant.value === ONBOARDING_VARIANTS.premium
  )
})
</script>

<template>
  <OnboardingPopover
    position="top"
    :is-visible="onboardingVisible"
    :title="$label(`${onboardingLabel}.title`)"
    :text="$label(`${onboardingLabel}.content`)"
  >
    <div
      :class="[
        'app-banner__wrapper position-relative overflow-hidden',
        { 'app-banner__wrapper--highlighted': onboardingVisible }
      ]"
    >
      <div class="app-banner container pt-72 pb-16 pb-lg-0">
        <div class="row">
          <div
            class="col-12 col-lg-4 offset-lg-1 d-flex justify-content-center align-items-lg-center"
          >
            <div>
              <h2 class="mb-16">
                {{ $label('page.myBooks.appPromoBanner.title') }}
              </h2>
              <p class="mb-24">
                {{ $label('page.myBooks.appPromoBanner.subtitle') }}
              </p>

              <div
                class="app-banner__badges d-flex align-items-center flex-wrap"
              >
                <a
                  id="googlePlay"
                  :href="$label('page.myBooks.appPromoBanner.googlePlayLink')"
                  target="_blank"
                >
                  <GooglePlayBadge width="160" height="48" />
                </a>

                <a
                  :href="$label('page.myBooks.appPromoBanner.appleStoreLink')"
                  target="_blank"
                >
                  <AppStoreBadge width="160" height="48" />
                </a>
              </div>
            </div>
          </div>

          <div
            class="col-12 col-lg-7 d-flex flex-column justify-content-center justify-content-lg-end align-items-lg-end pl-lg-0 pr-lg-32"
          >
            <BaseLazyImage
              :src="`${imageUrl}?image=fe-assets/app-banner-img.png&width=762&height=448&fit=contain`"
              class="app-device__img"
              :width="762"
              :height="448"
              fluid
              placeholder-color="transparent"
            />
          </div>
        </div>
      </div>

      <BCIcon class="bc-icon" />
    </div>
  </OnboardingPopover>
</template>

<style lang="scss" scoped>
.app-banner__wrapper {
  background: $grey-01;
  z-index: 0;

  &--highlighted {
    z-index: $popover-highlighted-el-zindex;
  }
}

.bc-icon {
  fill: $grey-02;
  position: absolute;
  z-index: -1;
  width: 130vw;
  transform: rotate(71.63deg);
  left: -5vw;
  bottom: -57vw;
}

.app-banner__badges {
  margin-bottom: 72px;
  gap: 16px;

  svg {
    width: 160px;
  }
}

@include media-breakpoint-down(lg) {
  .app-banner__wrapper {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }

  .app-banner__badges {
    justify-content: center;
    gap: 8px;
    margin-bottom: 60px;
  }

  .app-device__img {
    margin-left: -10vw;
  }

  .bc-icon {
    width: 348vw;
    left: -65vw;
    bottom: -176vw;
  }
}
</style>
