import { defineStore } from 'pinia'
import { $fetchApi } from '~/composables/useFetchApi'

export const usePromotionStore = defineStore('promotion', {
  state: () => ({
    landingPage: {},
    promoCode: '',
    promotionData: {},
    isValid: false
  }),
  getters: {
    isPromoCodeValid: (state) => state.isValid,
    promotion: (state) => ({
      ...state.promotionData,
      promoCode: state.promoCode
    })
  },
  actions: {
    setLandingPage(landingPage) {
      this.landingPage = landingPage
    },
    async getLandingPage(slug) {
      const response = await $fetchApi(`/nbi/proxy/landingpages/slug/${slug}`)

      if (response) {
        this.setLandingPage(response)
      }

      return response
    },

    async validatePromoCode({ promotionCode, isAuthenticated }) {
      this.isValid = false

      const endpoint = isAuthenticated
        ? '/nbi/proxy/promotions/uservalidate'
        : '/nbi/proxy/promotions/validate'

      const response = await $fetchApi(endpoint, {
        method: 'POST',
        body: { promotionCode }
      })

      this.promoCode = promotionCode
      this.isValid = true
      this.promotionData = response

      return response
    },

    setPromotionValidity(isValid) {
      this.isValid = isValid
    },

    setPromotionCode(promoCode) {
      this.promoCode = promoCode
    }
  }
})
