import { defineStore } from 'pinia'
import { $fetchApi } from '~/composables/useFetchApi'
import { usePaymentStore } from './payment'

const getInitialShopCartState = () => ({
  totalItems: 0,
  cartLoaded: false,
  isLoading: false,
  data: {},
  removingItem: false,
  paymentSessionDetails: {},
  invalidCartItems: []
})

export const useShopCartStore = defineStore('shopCart', {
  state: () => getInitialShopCartState(),
  getters: {
    cartData: (state) => state.data,
    paymentSession: (state) => state.paymentSessionDetails.paymentSession,
    paymentSessionId: (state) => state.paymentSessionDetails.paymentSessionId,
    orderNumber: (state) => state.paymentSessionDetails.orderNumber
  },
  actions: {
    setCart(cart) {
      this.data = cart
      this.totalItems = cart.items.length
      this.cartLoaded = true
    },
    async getCart() {
      try {
        if (this.isLoading) return
        this.isLoading = true

        const response = await $fetchApi('/nbi/proxy/carts/shopmulticart')
        this.setCart(response)
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.setCart({ items: [] })
        } else {
          throw error
        }
      } finally {
        this.isLoading = false
      }
    },
    async addToCart({ cartUid, productUid, price }) {
      const response = await $fetchApi('/nbi/proxy/carts/shopmulticart/add', {
        method: 'PUT',
        body: {
          productUid,
          productTargetType: 'Book',
          price,
          currency: 'EUR',
          cartUid
        }
      })
      this.setCart(response)
    },
    async removeFromCart({ cartUid, productUid }) {
      try {
        this.removingItem = true

        const response = await $fetchApi(
          '/nbi/proxy/carts/shopmulticart/remove',
          {
            method: 'PUT',
            body: { cartUid, productUid }
          }
        )

        this.setCart(response)
      } finally {
        this.removingItem = false
      }
    },
    clearCart() {
      this.$state = getInitialShopCartState()
    },
    async createPaymentSession(body) {
      const paymentStore = usePaymentStore()
      paymentStore.setPurchasedItems({})

      const response = await $fetchApi(
        '/proxy/v2/carts/shopmulticart/checkout',
        { method: 'POST', body }
      )
      if (response.invalidCartItems.length) {
        this.invalidCartItems = response.invalidCartItems
      } else if (response.paymentSessionDetails) {
        this.paymentSessionDetails = response.paymentSessionDetails
        this.invalidCartItems = []
      }
    },
    resetPaymentSession() {
      this.paymentSessionDetails = {}
      this.invalidCartItems = []
    }
  },
  share: {
    omit: [
      'cartLoaded',
      'isLoading',
      'paymentSessionDetails',
      'invalidCartItems'
    ],
    enable: true,
    initialize: true
  }
})
