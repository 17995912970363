import { usePromotionStore } from '~/stores/promotion'
import { PAGES } from '~/utils/constants'

export default defineNuxtRouteMiddleware(async (to) => {
  const {
    path,
    params: { slug },
    query
  } = to

  if (path === PAGES.redeemPagePath) {
    return
  }

  try {
    const promotionStore = usePromotionStore()
    await promotionStore.getLandingPage(slug)
  } catch (error) {
    const { $sentryCaptureException } = useNuxtApp()
    $sentryCaptureException(error)

    return navigateTo({
      path: `/promo/${PAGES.redeemPageSlug}`,
      query
    })
  }
})
