import { useAccountStore } from '~/stores/account'
import { usePaymentStore } from '~/stores/payment'
import { useSubscriptionsStore } from '~/stores/subscriptions'
import { useShopCartStore } from '~/stores/shopCart'
import { useBooksStore } from '~/stores/books'
import { useBooksProgressStore } from '~/stores/booksProgress'

interface SignInData {
  email: string
  password: string
}

export async function signIn(
  userData: SignInData,
  callbackUrl: string | undefined
) {
  const { $localStorage } = useNuxtApp()
  const { sentrySetUserId } = useSentry()
  const { signIn } = useAuth()
  const { email, password } = userData
  const signInOptions = callbackUrl ? { callbackUrl } : { redirect: false }

  await signIn({ email, password }, signInOptions, undefined, {
    'X-Device': 'Website'
  })

  const redirectCookie = useCookie('redirect', { maxAge: 300 })
  if (redirectCookie) {
    redirectCookie.value = null
  }

  $localStorage.addItem('sessionStatus', 'authenticated', 1)

  sentrySetUserId()
}

export async function signOut() {
  const { $localStorage, $sentrySetUser } = useNuxtApp()
  const { signOut } = useAuth()
  const route = useRoute()

  await signOut({ redirect: false })

  const accountStore = useAccountStore()
  accountStore.clearAccountCredentials()
  const subscriptionsStore = useSubscriptionsStore()
  subscriptionsStore.clearCart()
  const shopCartStore = useShopCartStore()
  shopCartStore.clearCart()
  const paymentStore = usePaymentStore()
  paymentStore.clearPaymentSession()
  const booksProgressStore = useBooksProgressStore()
  booksProgressStore.clearBooksProgress()
  const bookStore = useBooksStore()
  bookStore.clearBooksData()

  $localStorage.addItem('sessionStatus', 'unauthenticated', 1)
  $sentrySetUser(null)

  if (route.meta?.middleware === 'authentication') {
    await navigateTo('/')
  }
}

export function useAuthLib() {
  return {
    signIn,
    signOut
  }
}
