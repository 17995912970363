<template>
  <BaseButton
    variant="naked"
    :data-quantity="totalItems"
    aria-label="open cart"
    class="counter position-relative d-flex justify-content-center reset-btn-styling align-items-center mr-16 mr-lg-32"
    @click="onClick"
  >
    <CartIcon width="20px" height="20px" />
  </BaseButton>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useShopCartStore } from '~/stores/shopCart'
import CartIcon from '~/assets/icons/cart.svg'
import { TRACKING_LISTS } from '~/utils/constants'
import { useDrawer } from '~/composables/useDrawer'

export default defineComponent({
  components: {
    CartIcon
  },
  props: {
    whiteVariant: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { updateDrawerState } = useDrawer()

    return {
      updateDrawerState
    }
  },
  computed: {
    ...mapState(useShopCartStore, ['cartData', 'totalItems', 'cartLoaded'])
  },
  async mounted() {
    try {
      if (!this.cartLoaded) {
        await this.getCart()
      }
    } catch (error) {
      this.$sentryCaptureException(error)
    }
  },

  methods: {
    ...mapActions(useShopCartStore, ['getCart']),
    onClick() {
      const cartDrawer = {
        menuPositionClass: 'right',
        title: 'cart.drawerTitle',
        component: 'cart-drawer',
        footer: 'cart-drawer-footer'
      }

      this.updateDrawerState({
        drawerOptions: cartDrawer,
        isDrawerShown: true
      })

      if (this.totalItems) {
        this.sendViewCartTrackingEvent()
      }
    },
    sendViewCartTrackingEvent() {
      try {
        const items = this.cartData.items.map(({ bookDetails: book }) => ({
          id: book.uid,
          name: book.title,
          price: book.listPrice,
          discount: book.listPrice - book.finalPrice,
          ...TRACKING_LISTS.shop
        }))

        this.$gtmTracking.viewCart(this.cartData.totalPrice, items)
        // eslint-disable-next-line no-empty
      } catch {}
    }
  }
})
</script>

<style lang="scss" scoped>
.counter {
  width: 26px;
  height: 29.5px;
}

.counter[data-quantity]:after {
  color: $white;
  position: absolute;
  top: 0;
  right: -3px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 15px;
  height: 15px;
  font-size: 10px;
  font-weight: $font-weight-semibold;
  text-align: center;
  content: attr(data-quantity);
  background-color: $green;
  border-radius: 0.75rem;
}
</style>
