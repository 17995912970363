<template>
  <div
    :class="[
      'subscription-products container-fluid',
      {
        'subscription-products--with-background position-relative overflow-hidden pt-56 pt-md-72 pb-64':
          withBackground
      }
    ]"
  >
    <BCIcon v-if="withBackground" class="bc-icon" />
    <h2 v-if="title" class="text-center mb-16 mb-md-24" data-testid="title">
      {{ title }}
    </h2>
    <p v-if="subtitle" class="text-center mb-32 mb-md-40">{{ subtitle }}</p>

    <SubscriptionProductsSkeleton v-if="isFetchingProducts" />

    <transition name="slide-in">
      <div
        v-if="products.length > 0 && !isFetchingProducts"
        class="d-flex justify-content-center flex-wrap align-items-end"
      >
        <SubscriptionProduct
          v-for="product in products"
          :key="product.id"
          :product="product"
          :on-select="selectProduct"
          :pre-selected-btn-label="preSelectedBtnLabel"
          :select-btn-label="selectBtnLabel"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useUserStore } from '~/stores/user'
import { useSubscriptionsStore } from '~/stores/subscriptions'
import SubscriptionProductsSkeleton from '~/components/subscriptions/SubscriptionProductsSkeleton'
import SubscriptionProduct from '~/components/subscriptions/SubscriptionProduct'
import {
  TRACKING_LISTS,
  CART_VALIDATION_ERRORS,
  PAGES
} from '~/utils/constants'
import BCIcon from '~/assets/icons/bc-icon.svg'

export default {
  components: {
    SubscriptionProductsSkeleton,
    SubscriptionProduct,
    BCIcon
  },
  props: {
    products: {
      type: Array,
      default: () => []
    },
    isFetchingProducts: {
      type: Boolean,
      default: false
    },
    withBackground: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    preSelectedBtnLabel: {
      type: String,
      default: undefined
    },
    selectBtnLabel: {
      type: String,
      default: undefined
    }
  },
  computed: {
    ...mapState(useUserStore, ['loggedInUser'])
  },
  mounted() {
    try {
      if (this.products.length) {
        this.$gtmTracking.viewItemList({
          ...TRACKING_LISTS.defaultSub,
          products: this.products.map((product) => ({
            ...product,
            ...TRACKING_LISTS.defaultSub
          }))
        })
      }
      // eslint-disable-next-line no-empty
    } catch {}
  },
  methods: {
    ...mapActions(useSubscriptionsStore, ['addToCart', 'getCart']),
    async selectProduct(product) {
      try {
        this.$gtmTracking.addToCart(
          {
            ...product,
            ...TRACKING_LISTS.defaultSub
          },
          product.price
        )

        if (this.loggedInUser) {
          await this.getCart()
          await this.addToCart({
            productUid: product.id
          })

          this.$router.push(PAGES.subscriptionChangeOverview)
        } else {
          const productId = this.$crypt.encrypt(product.id)
          this.$router.push({
            path: '/aanmelden',
            query: { id: productId }
          })
        }
      } catch (error) {
        const errorCode = this.$error.getLabel(error)
        const isCartValidationError = CART_VALIDATION_ERRORS.includes(errorCode)

        const errorLabel = isCartValidationError
          ? errorCode
          : 'messages.errorOccuredMessage'

        this.$toast.error(this.$label(errorLabel))

        if (!isCartValidationError) {
          this.$sentryCaptureException(error)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.subscription-products--with-background {
  background: $lilac-bright;
  z-index: 1;
}

.bc-icon {
  z-index: -1;
  position: absolute;
  fill: #c7e8f5;
  width: 532vw;
  left: -180vw;
  top: -215vw;
  transform: rotate(162.92deg);
}

@include media-breakpoint-up(md) {
  .subscription-products {
    padding-bottom: 72px;
  }
}

@include media-breakpoint-up(lg) {
  .slide-in-enter-active,
  .slide-in-leave-enter {
    transform: translateX(0);
    transition: all 0.3s linear;
  }
  .slide-in-enter-from,
  .slide-in-leave-to {
    transform: translateX(100%);
  }

  .bc-icon {
    width: 251.5vw;
    left: -48.9vw;
    top: -117.5vw;
  }
}
</style>
