import { defineStore, storeToRefs } from 'pinia'
import { ONBOARDING_VARIANTS } from '~/utils/constants'
import { dateToString } from '~/utils/monthFormatter'
import { useBooksStore } from './books'
import { useUserStore } from './user'

export const useOnboardingStore = defineStore('onboarding', {
  state: () => ({
    step: 0,
    variant: ONBOARDING_VARIANTS.premium
  }),
  getters: {
    label(state) {
      return `onboarding.${state.variant}.step${state.step}`
    }
  },
  actions: {
    nextStep(step) {
      this.step = step
    },
    async startOnboarding() {
      let variant = ONBOARDING_VARIANTS.premium
      const userStore = useUserStore()

      if (userStore.hasBasicSubscription) {
        const category = dateToString(new Date())
        const booksStore = useBooksStore()
        const { chosenBooks: stateChosenBooks } = storeToRefs(booksStore)

        let chosenBooks = stateChosenBooks.value(category)

        if (!chosenBooks) {
          await booksStore.getChosenBooks(category)
        }

        chosenBooks = stateChosenBooks.value(category)

        variant = chosenBooks.length
          ? ONBOARDING_VARIANTS.basicChosenBook
          : ONBOARDING_VARIANTS.basic
      }

      this.variant = variant
      this.step = 1
    }
  }
})
