<template>
  <div
    class="popover-footer mt-24 d-flex justify-content-between align-items-center flex-wrap"
  >
    <span class="text-small">
      {{ $label('onboarding.progress', { currentStep, totalSteps }) }}
    </span>

    <div class="d-flex">
      <BaseButton
        v-if="currentStep > 1"
        class="mr-8 mr-md-16"
        data-testid="prevStepBtn"
        @click="prevClicked"
      >
        {{ $label('onboarding.prev') }}
      </BaseButton>
      <BaseButton
        v-if="currentStep < totalSteps"
        variant="call-to-action"
        data-testid="nextStepBtn"
        @click="nextClicked"
      >
        {{ $label('onboarding.next') }}
      </BaseButton>
      <BaseButton
        v-if="currentStep === totalSteps"
        variant="call-to-action"
        data-testid="restartBtn"
        @click="restart"
      >
        {{ $label('onboarding.restart') }}
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useOnboardingStore } from '~/stores/onboarding'
import { ONBOARDING_VARIANTS } from '~/utils/constants'

export default {
  props: {
    onNext: {
      type: Function,
      default: () => {}
    },
    onPrev: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    ...mapState(useOnboardingStore, {
      currentStep: 'step',
      variant: 'variant'
    }),
    totalSteps() {
      if (this.variant === ONBOARDING_VARIANTS.premium) return 7

      return 5
    }
  },
  methods: {
    ...mapActions(useOnboardingStore, ['nextStep']),
    nextClicked() {
      this.onNext()

      const step = this.currentStep + 1

      this.nextStep(step)

      this.$gtmTracking.push({
        event: 'interact_event',
        eventAction: 'click',
        eventCategory: 'Onboarding',
        eventLabel: `Go to step: ${step}`
      })
    },
    prevClicked() {
      this.onPrev()

      this.nextStep(this.currentStep - 1)
    },
    restart() {
      this.nextStep(1)
    }
  }
}
</script>
