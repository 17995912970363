<template>
  <div>
    <VeeForm
      v-slot="{ meta: formMeta }"
      data-nw="profile-change-password-form"
      @submit="submit"
    >
      <div class="mb-16">
        <label for="currentPassword" class="form-label">
          {{ $label('account.profile.currentPasswordPlaceholder') }}
        </label>
        <VeeField
          v-slot="{ meta, field, errorMessage }"
          v-model="currentPassword"
          name="currentPassword"
          rules="required|min:6"
        >
          <input
            v-bind="field"
            id="current-password"
            data-nw="profile-current-password"
            :type="passwordFieldType"
            autocomplete="current-password"
            :is-valid="meta.valid && meta.touched"
            :class="[
              'form-control',
              { 'is-valid': meta.valid && meta.touched },
              { 'is-invalid': !meta.valid && meta.touched }
            ]"
            aria-describedby="password"
          />
          <div v-show="!meta.valid && meta.touched" class="invalid-feedback">
            {{ errorMessage }}
          </div>
        </VeeField>
      </div>

      <div class="mb-16">
        <label for="password" class="form-label">
          {{ $label('form.password') }}
        </label>
        <div id="passwordHelpBlock" class="form-text">
          {{ $label('form.passwordExplain') }}
        </div>
        <VeeField
          v-slot="{ meta, field, errorMessage }"
          v-model="password"
          name="password"
          rules="required|min:6"
        >
          <input
            v-bind="field"
            id="password"
            :type="passwordFieldType"
            autocomplete="new-password"
            :is-valid="meta.valid && meta.touched"
            :class="[
              'form-control',
              { 'is-valid': meta.valid && meta.touched },
              { 'is-invalid': !meta.valid && meta.touched }
            ]"
            aria-describedby="password"
            data-nw="profile-password"
          />
          <div v-show="!meta.valid && meta.touched" class="invalid-feedback">
            {{ errorMessage }}
          </div>
        </VeeField>
        <PasswordToggle :on-toggle="togglePasswordField" />
      </div>

      <BaseButton
        :disabled="!formMeta.valid || !formMeta.touched"
        :class="[{ loading: isLoading }, 'w-100']"
        variant="primary"
        type="submit"
        data-nw="password-submit"
      >
        {{ $label('common.save') }}
      </BaseButton>
    </VeeForm>
  </div>
</template>

<script>
import { useUserStore } from '~/stores/user'
import { useAccountStore } from '~/stores/account'
import ValidationMixin from '~/mixins/validation'
import PasswordToggle from '~/components/PasswordToggle'
import { useDrawer } from '~/composables/useDrawer'

export default {
  components: {
    PasswordToggle
  },
  mixins: [ValidationMixin],
  props: {
    onSubmit: {
      type: Function,
      default: () => {}
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { closeDrawer } = useDrawer()
    const userStore = useUserStore()
    const accountStore = useAccountStore()

    return {
      closeDrawer,
      userStore,
      accountStore
    }
  },
  data() {
    return {
      password: '',
      currentPassword: '',
      passwordFieldType: 'password'
    }
  },
  computed: {
    loggedInUser() {
      return this.userStore.loggedInUser
    }
  },
  methods: {
    async submit() {
      try {
        this.loading = true
        await this.accountStore.updateProfile({
          password: {
            currentPassword: this.currentPassword.trim(),
            repeatPassword: this.password.trim(),
            newPassword: this.password.trim()
          }
        })

        this.$toast.success(
          this.$label('messages.updateProfile.successMessage')
        )

        this.closeDrawer()

        this.clearPasswordFields()
      } catch ({ response }) {
        if (response && response.data) {
          const [error] = response.data
          this.$toast.error(error.description)
        } else {
          this.$toast.error(this.$label('messages.errorOccuredMessage'))
        }
      } finally {
        this.loading = false
      }
    },
    clearPasswordFields() {
      this.password = ''
      this.currentPassword = ''
    },
    togglePasswordField(type) {
      this.passwordFieldType = type
    }
  }
}
</script>
