import payload_plugin_qLmFnukI99 from "/home/vsts/work/1/s/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/home/vsts/work/1/s/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/vsts/work/1/s/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/vsts/work/1/s/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/home/vsts/work/1/s/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/home/vsts/work/1/s/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/vsts/work/1/s/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_immediate_client_xfI97IMoyr from "/home/vsts/work/1/s/node_modules/nuxt/dist/app/plugins/chunk-reload-immediate.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/vsts/work/1/s/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/vsts/work/1/s/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/vsts/work/1/s/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_I4dbrL2rYz from "/home/vsts/work/1/s/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import _02_sentry_client_sABltX8K3S from "/home/vsts/work/1/s/plugins/02.sentry.client.ts";
import _03_pinia_shared_state_s3sWJn8ZHW from "/home/vsts/work/1/s/plugins/03.pinia-shared-state.ts";
import _04_page_transition_client_YeHhmBhCZr from "/home/vsts/work/1/s/plugins/04.page-transition.client.ts";
import _05_labels_rqRf9z6ngz from "/home/vsts/work/1/s/plugins/05.labels.ts";
import active_campaign_client_zsFs51619E from "/home/vsts/work/1/s/plugins/active-campaign.client.js";
import crypto_client_EzZvOAgoY1 from "/home/vsts/work/1/s/plugins/crypto.client.js";
import error_ldt3PQauZ0 from "/home/vsts/work/1/s/plugins/error.ts";
import gtm_client_OzmBuHLRIb from "/home/vsts/work/1/s/plugins/gtm.client.ts";
import storage_client_n2KxJNDSfS from "/home/vsts/work/1/s/plugins/storage.client.js";
import text_sc37v2klBL from "/home/vsts/work/1/s/plugins/text.ts";
import toast_ysMjUcU7eJ from "/home/vsts/work/1/s/plugins/toast.ts";
import useBootstrap_client_ouDJvcVteA from "/home/vsts/work/1/s/plugins/useBootstrap.client.ts";
import vee_validate_KcKlKmvCrJ from "/home/vsts/work/1/s/plugins/vee-validate.ts";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  chunk_reload_immediate_client_xfI97IMoyr,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_I4dbrL2rYz,
  _02_sentry_client_sABltX8K3S,
  _03_pinia_shared_state_s3sWJn8ZHW,
  _04_page_transition_client_YeHhmBhCZr,
  _05_labels_rqRf9z6ngz,
  active_campaign_client_zsFs51619E,
  crypto_client_EzZvOAgoY1,
  error_ldt3PQauZ0,
  gtm_client_OzmBuHLRIb,
  storage_client_n2KxJNDSfS,
  text_sc37v2klBL,
  toast_ysMjUcU7eJ,
  useBootstrap_client_ouDJvcVteA,
  vee_validate_KcKlKmvCrJ
]