<!-- eslint-disable vue/no-v-html -->
<template>
  <DangerAlert v-if="hasFailedAutorenewal">
    <span
      v-html="
        $label(`user.autorenewal.${lastAutoRenewalStatus}`, {
          date: autoRenewalDate,
          paymentLink: `/betalen?autoRenewal=${autoRenewalUid}`
        })
      "
    ></span>
  </DangerAlert>
</template>

<script>
import { useUserStore } from '~/stores/user'
import DangerAlert from '~/components/alerts/DangerAlert'
import dateFormatter from '~/utils/dateFormatter'

export default {
  components: {
    DangerAlert
  },
  setup() {
    const userStore = useUserStore()

    return {
      userStore
    }
  },
  computed: {
    loggedInUser() {
      return this.userStore.loggedInUser
    },
    autoRenewalDate() {
      const date =
        this.loggedInUser && this.loggedInUser.lastAutoRenewalExecutionDate

      return dateFormatter(date)
    },
    lastAutoRenewalStatus() {
      const status =
        this.loggedInUser && this.loggedInUser.lastAutoRenewalStatus

      return (status && status[0].toLowerCase() + status.slice(1)) || ''
    },
    autoRenewalUid() {
      return this.loggedInUser && this.loggedInUser.lastAutoRenewalUid
    },
    hasFailedAutorenewal() {
      const failedStatus = [
        'inconsistency',
        'review',
        'aborted',
        'failed',
        'replacedForNewPayment'
      ]

      return failedStatus.includes(this.lastAutoRenewalStatus)
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-danger {
  border-radius: 0;

  :deep(.container) {
    padding: 0;
    justify-content: center;
  }
}
</style>
