import { defineStore } from 'pinia'
import { v4 as uuidv4 } from 'uuid'

export const useToastStore = defineStore('toast', {
  state: () => ({
    message: null
  }),
  actions: {
    dismissToastMessage() {
      this.message = null
    },
    addToastMessage(payload) {
      this.message = {
        ...payload,
        id: uuidv4()
      }
    }
  }
})
