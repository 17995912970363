<template>
  <header class="mt-24 mt-md-40">
    <div class="container">
      <slot name="breadcrumb" />
      <h1 class="mb-0" data-testid="page-title">
        {{ header }}
      </h1>
      <p v-if="subtitle" class="page-subtitle text-large mt-16 mt-lg-8 mb-0">
        {{ subtitle }}
      </p>
    </div>

    <slot name="divider">
      <hr class="mt-32 mt-md-48 mb-40" />
    </slot>
  </header>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.page-subtitle {
  max-width: 342px;
}

@include media-breakpoint-up(md) {
  .page-subtitle {
    max-width: 800px;
  }
}
</style>
