import { defineStore } from 'pinia'
import { $fetchApi } from '~/composables/useFetchApi'

export const useBooksProgressStore = defineStore('booksProgress', {
  state: () => ({
    items: [],
    updatingProgress: false
  }),
  getters: {
    bookProgress: (state) => {
      return (slug) => {
        if (!state.items.length) return null

        return state.items.find((book) => book.bookSlug === slug)
      }
    }
  },
  actions: {
    async getBookProgress({ userId, bookSlug }) {
      const stateBookProgress = this.bookProgress(bookSlug)

      if (stateBookProgress) {
        return stateBookProgress
      }

      const response = await $fetchApi(
        `/book/progress?userId=${userId}&bookSlug=${bookSlug}`
      )

      if (response.items.length) {
        const resItem = response.items[0]
        const itemAlreadyExists = this.items.some(
          (item) => item.id === resItem.id
        )

        if (itemAlreadyExists) {
          this.items = this.items.map((item) =>
            item.id === resItem.id ? { ...item, ...resItem } : item
          )
        } else {
          this.items = [...this.items, resItem]
        }
      }

      return response
    },
    async getSelectionProgress({ userId, catalog, category }) {
      const response = await $fetchApi(
        `/book/progress?userId=${userId}&catalog=${catalog}&category=${category}`
      )

      this.items = [...this.items, ...response.items]
    },
    async createBookProgress(data) {
      if (this.updatingProgress) return

      this.updatingProgress = true

      const response = await $fetchApi('/book/progress', {
        method: 'POST',
        body: data
      })

      this.items = [response, ...this.items]
      this.updatingProgress = false
    },
    async updateBookProgress(data) {
      if (this.updatingProgress) return

      this.updatingProgress = true

      const response = await $fetchApi('/book/progress', {
        method: 'PUT',
        body: data
      })

      this.items = [
        ...this.items.map((item) =>
          item.id !== response.id ? item : { ...item, ...response }
        )
      ]
      this.updatingProgress = false
    },
    clearBooksProgress() {
      this.items = []
      this.updatingProgress = false
    }
  },
  share: {
    omit: ['updatingProgress'],
    enable: true,
    initialize: true
  }
})
