/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  defineNuxtRouteMiddleware,
  useRuntimeConfig,
  navigateTo,
  useNuxtApp,
  useAuth
} from '#imports'
import { useUserStore } from '~/stores/user'
import { useSubscriptionsStore } from '~/stores/subscriptions'
import { useSubscription } from '~/composables/useSubscription'

export const updateUserData = async (
  userStore: any,
  $sentryCaptureException: (error: any) => void
) => {
  try {
    await userStore.updateUserData()
  } catch (error) {
    $sentryCaptureException(error)
  }
}

export default defineNuxtRouteMiddleware(async (_, { fullPath, query }) => {
  const {
    public: { BASIC_AND_PREMIUM }
  } = useRuntimeConfig()
  const { $sentryCaptureException } = useNuxtApp()
  const { data }: any = useAuth()
  const userHasSubscription = data.value?.currentMonthSubscription
  const { isSubscriptionUpdate } = useSubscription()
  const subscriptionsStore = useSubscriptionsStore()
  const paymentType =
    (subscriptionsStore.cart as { paymentType?: string })?.paymentType || ''

  if (query?.autoRenewal && !data.value) {
    const cookie = useCookie('redirect', {
      maxAge: 300
    })
    cookie.value = fullPath
    return navigateTo({ path: '/inloggen', query }, { redirectCode: 302 })
  }

  if (
    BASIC_AND_PREMIUM === '1' &&
    !userHasSubscription &&
    isSubscriptionUpdate(paymentType)
  ) {
    const userStore = useUserStore()
    return await updateUserData(userStore, $sentryCaptureException)
  }

  if (BASIC_AND_PREMIUM !== '1' || !userHasSubscription) {
    return navigateTo({
      path: 'betalen',
      query
    })
  }
})
