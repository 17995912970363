export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1"},{"name":"robots","content":"noarchive"},{"name":"description","content":"Bookchoice: elke maand de beste e-books en luisterboeken"}],"link":[{"rel":"preload","as":"font","type":"font/woff","href":"https://www.bookchoice.com/fonts/dm-sans-v15-latin-regular.woff2","crossorigin":"anonymous"},{"rel":"preload","as":"font","type":"font/woff","href":"https://www.bookchoice.com/fonts/dm-sans-v15-latin-italic.woff2","crossorigin":"anonymous"},{"rel":"preload","as":"font","type":"font/woff","href":"https://www.bookchoice.com/fonts/dm-sans-v15-latin-600.woff2","crossorigin":"anonymous"},{"rel":"preload","as":"font","type":"font/woff","href":"https://www.bookchoice.com/fonts/lora-v35-latin-italic.woff2","crossorigin":"anonymous"},{"rel":"preload","as":"font","type":"font/woff","href":"https://www.bookchoice.com/fonts/lora-v35-latin-500.woff2","crossorigin":"anonymous"},{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"}],"style":[],"script":[{"type":"application/ld+json","innerHTML":{"@context":"http://schema.org","@type":"Organization","name":"Bookchoice","url":"http://www.bookchoice.nl","contactPoint":[{"@type":"ContactPoint","email":"support@bookchoice.com","areaServed":"NL"}],"sameAs":["https://www.facebook.com/BookchoiceNL","https://www.instagram.com/bookchoice"],"description":"Bookchoice: elke maand de beste e-books en luisterboeken"}},{"src":"/browser-update.js","type":"text/javascript","async":true,"defer":true}],"noscript":[],"title":"Bookchoice: elke maand de beste ebooks & audioboeken","htmlAttrs":{"lang":"nl-NL"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = "https://www.bookchoice.com"

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = false

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = false