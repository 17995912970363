import { defineStore } from 'pinia'
import { useRuntimeConfig } from '#imports'
import { SUBSCRIPTION_CATEGORIES } from '~/utils/constants'
import { $fetchApi } from '~/composables/useFetchApi'

export const useSubscriptionsStore = defineStore('subscriptions', {
  state: () => ({
    products: [],
    cart: {},
    cartIsLoaded: false
  }),
  getters: {
    product(state) {
      return (subscriptionTypeId) => {
        return state.products.find(({ id }) => id === subscriptionTypeId)
      }
    }
  },
  actions: {
    async getProducts() {
      if (this.products.length) return Promise.resolve(this.products)

      const response = await $fetchApi(
        'v1/subscriptions/products?subscriptionCategoryCode=DefaultSubscriptionTypes'
      )

      if (response && Array.isArray(response)) {
        response.sort((curr, prev) => {
          if (curr.visibilityOrderPosition < prev.visibilityOrderPosition) {
            return -1
          }
          if (curr.visibilityOrderPosition > prev.visibilityOrderPosition) {
            return 1
          }
          return 0
        })
        this.products = response
      }

      return response
    },
    async getCart() {
      if (this.cartIsLoaded) return

      const config = useRuntimeConfig()
      const enableBasicAndPremium = config.public.BASIC_AND_PREMIUM === '1'

      const data = await $fetchApi(
        `/v2/carts/subscriptionCart?enableBasicAndPremium=${enableBasicAndPremium}`
      )

      this.cart = data || {}
      this.cartIsLoaded = true
    },
    async addToCart(body) {
      const config = useRuntimeConfig()
      const cartUid = this.cart.uid

      const endpoint = cartUid
        ? `/v2/carts/subscriptionCart/${cartUid}/add`
        : '/v2/proxy/carts/subscriptionCart'

      if (cartUid && body.productUid === this.cart.items[0].itemUid) {
        return
      }

      const response = await $fetchApi(endpoint, {
        method: 'POST',
        body: {
          productTargetType: 'subscription',
          ...body,
          enableBasicAndPremium: config.public.BASIC_AND_PREMIUM === '1'
        }
      })

      if (response) {
        this.cart = response
        this.cartIsLoaded = true
      }
    },
    async requestUpgrade() {
      const response = await $fetchApi(
        `v1/subscriptions/products?subscriptionCategoryCode=${SUBSCRIPTION_CATEGORIES.upgrade}`
      )

      const [product] = response

      await this.addToCart({
        productUid: product.id
      })
    },
    async requestDowngrade() {
      const response = await $fetchApi(
        `v1/subscriptions/products?subscriptionCategoryCode=${SUBSCRIPTION_CATEGORIES.downgrade}`
      )

      const [product] = response

      await this.addToCart({
        productUid: product.id
      })
    },
    async addFailedAutoRenewalSubToCart(autoRenewalUid) {
      const response = await $fetchApi(
        `/v2/proxy/carts/autorenewal/${autoRenewalUid}`
      )

      if (response) {
        this.cart = response
        this.cartIsLoaded = true
      }
    },
    clearCart() {
      this.cart = {}
      this.cartIsLoaded = false
    }
  },
  share: {
    enable: true,
    initialize: true
  }
})
