import * as Sentry from '@sentry/vue'
import type { Router } from 'vue-router'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const { vueApp } = nuxtApp

  Sentry.init({
    app: vueApp,
    dsn: config.public.SENTRY_DSN,
    enabled: !!config.public.SENTRY_ENABLED,
    release: config.public.SENTRY_RELEASE,
    environment: config.public.SENTRY_ENVIRONMENT,
    autoSessionTracking: true,
    debug: false,
    integrations: [
      Sentry.browserTracingIntegration({ router: nuxtApp.$router as Router }),
      Sentry.replayIntegration()
    ],
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: [
      'Assets not found',
      'Not an Element',
      'Non-Error promise rejection captured with value',
      `Cannot read properties of undefined (reading 'render')`,
      'Promo code is missing'
    ],
    maxValueLength: 1000
  })

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException,
      sentrySetExtra: Sentry.setExtra
    }
  }
})
